import { acceptHMRUpdate, defineStore } from "pinia";
import type { CustomerAddressItem } from "~/types/ecom/address.type";
import EcomCustomerService from "../services/ecom/customer.service";
import { toast } from "vue3-toastify";

export const useAccountStore = defineStore("account", {
  state: () => ({
    profile: <any>null,
    addresses: <CustomerAddressItem[] | null>null,
    profile_address_form_visible: false,
    is_saving_profile_address: false,
    is_addresses_updating: false,

    // 3 | edit username state below
    profile_edit_user_name: '',
    profile_edit_confirm_user_name: '',
    profile_edit_update_email: false,
    open_edit_username_modal: false,
    tracking_order_data: [],

    is_orders_fetching: true,
    is_addresses_fetching: true,
    is_profile_fetching: true,
    is_profile_save_button_loading: false,
    is_update_password_button_loading: false,
    is_profile_delete_success: false,
    account_delete_modal: false,
    orders: [],
    selected_order_ids: [],
    profile_title_options: [
      { name: "Mr", code: "Mr" },
      { name: "Mrs", code: "Mrs" },
    ],
    change_password_inputs: {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
    loading: false,
    errors: {
      password: "",
      new_password: "",
      new_password_confirmation: "",
    },
    is_current_password_verified: false,
    orders_response: null,
    trade_account_value: "Trade Credit",
    order_query: {
      page: 1,
      page_limit: 5,
    },
    contact_preferences: null,
    preference_order: [
      {
        key: "order_progress",
        title: {
          key: 'orderProgress',
          value: 'Order Progress'
        },
        message: {
          key: 'orderProgressMessage',
          value: 'How would you like to be kept informed of the shipping status of your order?'
        }
      },
      {
        key: "order_query",
        title: {
          key: 'orderQuery',
          value: 'Order related questions'
        },
        message: {
          key: 'orderQueryMessage',
          value: 'How may we contact you if we have questions about your order prior to delivery? There may be several reasons why we would like to contact you, for example in the event of a delay or when an item is out of stock.'
        }
      },
      {
        key: "offers_info",
        title: {
          key: 'offersInfo',
          value: 'Offers & info'
        },
        message: {
          key: 'offersInfoMessage',
          value: 'How would you like to be kept informed by us about the latest offers and promotions?'
        },
      },
      {
        key: "mail_catalogues",
        title: {
          key: 'freeCatalogue',
          value: 'Free Catalogue'
        },
        message: {
          key: 'freeCatalogueMessage',
          value: 'Can we send you a free catalogue every now and then?'
        }
      }

    ],
  }),
  getters: {},
  actions: {
    async userLogin() {
      if (this.profile) {
        this.is_profile_fetching = false;
        return;
      }

      let username = "kulchandra-k001@webreinvent.com";
      let password = "3pE/d!jdRr9w.uh";

      try {
        let body = {
          username: username,
          password: password,
        };

        const response = await useAjaxEcom("/customers/auth/login", {
          method: "post",
          params: body,
        });

        this.profile = response.data;
        useRootStore().authenticatedCustomer = response.data;

        this.is_profile_fetching = false;
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    async getSavedAddresses(customer_id?: string) {
      if (!this.profile && !useAuthStore().user) {
        return;
      }

      try {
        const response = await EcomCustomerService.getAddresses(
          customer_id || this.profile.id
        );

        this.addresses = response.data;
        this.is_addresses_fetching = false;
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    async addAddress(
      customer_id?: string,
      customer_token?: string,
      address: any = {
        type: 2,
        line_1: "Sec 21",
        line_2: "Karol Bagh",
        line_3: "New Delhi",
        town: "Delhi",
        county: "India",
        postcode: "110051",
        country_id: 8,
      }
    ) {
      if (!this.profile && !useAuthStore().user) {
        return;
      }

      try {
        let options = {
          method: "post",
          params: address,
          headers: {
            "X-Toolstation-Customer-Id": customer_token || this.profile?.token,
          },
        };

        const res = await useAjaxEcom(
          `/customers/address-book/${customer_id || this.profile.id}`,
          options
        );

        await this.getSavedAddresses(customer_id);
        toast("User Address Updated Successfully", {
          autoClose: true,
          type: "success",
        });
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    async setAddressAsPrimary(address_id: string, customer_id?: string) {
      if (!this.profile && !useAuthStore().user) {
        return;
      }

      try {
        await EcomCustomerService.setAddressAsPrimary(
          customer_id || this.profile.id,
          address_id
        );

        await this.getSavedAddresses(customer_id);
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    closeModal() {
      this.profile_edit_user_name = "";
      this.profile_edit_confirm_user_name = "";
      this.profile_edit_update_email = false;
      this.open_edit_username_modal = false;
    },
    async updateUserName(name: string, customerId: string, isEmailLabelCheck: boolean) {
      try {
        this.loading = true;

        let payload = {
          ...this.profile,
          username: name,
        };

        if (isEmailLabelCheck) {
          payload = {
            ...payload,
            email: name,
          }
        }

        const response = await useAjaxEcom(`/customers/${customerId}`, {
          method: "patch",
          params: payload,
        });

        if (response?.data?.error?.code === '422.99') {
          toast('The username has already been taken.', {
            autoClose: true,
            type: "error",
          });
        }
        else if (response?.data) {
          this.profile = response.data;
          useAuthStore().user = response.data;

          toast(`${isEmailLabelCheck ? 'Username & Email updated successfully' : 'Username updated successfully'}`, {
            autoClose: true,
            type: "success",
          });
          this.closeModal();
        }
        this.loading = false;
      } catch (err) {
        await useErrorHandler(err);
      }
    },

    async deleteAddress(address_id: string, customer_id?: string) {
      if (!this.profile && !useAuthStore().user) {
        return;
      }

      try {
        const response = await EcomCustomerService.deleteAddress(address_id);

        if (response.data.length > 0) {
          toast("Address Deleted Successfully", {
            autoClose: true,
            type: "success",
          });
        }

        await this.getSavedAddresses(customer_id);
      } catch (err) {
        await useErrorHandler(err);
      }
    },

    async updateAddress(options: {
      customer_id?: string;
      address_id: string;
      new_address: CustomerAddressItem;
    }) {
      try {
        await EcomCustomerService.updateAddress(
          options.address_id,
          options.new_address
        );
        await this.getSavedAddresses(options.customer_id);
      } catch (err) {
        await useErrorHandler(err);
        return err;
      }
    },

    async getOrders(page = 1) {
      this.order_query.page = page;

      if (!this.profile) {
        return;
      }

      try {
        this.orders_response = await EcomCustomerService.getOrders(
          this.profile.id,
          this.order_query.page,
          this.order_query.page_limit
        );

        this.orders =
          page === 1
            ? this.orders_response.data
            : this.orders.concat(this.orders_response.data);

        this.is_orders_fetching = false;
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    async getOrderInvoice(
      order_id: string,
      stream_response = true,
      full_invoice = true
    ) {
      if (!this.profile) {
        return;
      }

      try {
        let options = {
          method: "post",
          params: {
            full_invoice: full_invoice,
            stream_response: stream_response,
          },
          headers: {
            "X-Toolstation-Customer-Id": this.profile.token,
          },
        };

        let invoice_response = await useAjaxEcom(
          "/orders/" + order_id + "/invoice",
          options
        );

        if (invoice_response instanceof Blob) {
          if (stream_response) {
            // window.open(URL.createObjectURL(invoice_response));
            this.downloadFile(invoice_response, order_id);
            return;
          }
          this.downloadFile(invoice_response, order_id);
        }
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    async getOrderTrackingLinks(order_id: string) {
      if (!this.profile) return;

      try {
        const res = await useAjaxEcom(`/orders/${order_id}/tracking-links`)
        console.log(res);
      } catch (err) {
        useErrorHandler(err);
        return err
      }
    },
    async updateContactPreferences() {
      if (!this.profile) {
        return;
      }

      try {
        let options = {
          method: "put",
          params: this.contact_preferences,
          headers: {
            "X-Toolstation-Customer-Id": this.profile.token,
          },
        };

        let customer_res = await useAjaxEcom(
          "/customers/" + this.profile.id + "/contact-preferences",
          options
        );

        if (
          customer_res &&
          customer_res.data &&
          customer_res.data.contact_preferences
        ) {
          this.profile = customer_res.data;
          this.contact_preferences = useRootStore().clone(
            this.profile.contact_preferences
          );
          useAuthStore().user = useRootStore().clone(this.profile);
          await useRootStore().toastSuccess(
            ["Successfully updated."],
            "top-right"
          );
        }
      } catch (err) {
        await useErrorHandler(err);
      }
    },
    async getLoyaltyClub() {
      if (!this.profile) {
        return;
      }

      try {
        let options = {
          headers: {
            "X-Toolstation-Customer-Id": this.profile.token,
          },
        };

        let loyalty_res = await useAjaxEcom(
          "/customers/" +
          this.profile.id +
          "/loyalty-club/rewards-platform-sso-url",
          options
        );
      } catch (err) {
        await useErrorHandler(err);
      }
    },

    downloadFile(blob, fileName) {
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },

    downloadInvoices() {
      let self = this;
      this.selected_order_ids.forEach(function (order_id) {
        self.getOrderInvoice(order_id, false);
      });
    },

    //----------------------------------------------------------
    toLabel(str: string) {
      if (typeof str === "string") {
        str = str.replace(/_/g, " ");
        str = this.toUpperCaseWords(str);
        return str;
      }
    },
    //----------------------------------------------------------
    toUpperCaseWords(str: string) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
    //----------------------------------------------------------
    async updateCustomer(e) {
      e.preventDefault();

      if (!this.profile) {
        return;
      }

      try {
        this.is_profile_save_button_loading = true;

        let profile_params = {
          title: this.profile.title,
          first_name: this.profile.first_name,
          last_name: this.profile.last_name,
          email: this.profile.email,
          mobile: this.profile.mobile,
          company: this.profile.company,
          vat_number: this.profile.vat_number,
          telephone: this.profile.telephone,
        };

        let options = {
          method: "patch",
          params: profile_params,
          headers: {
            "X-Toolstation-Customer-Id": this.profile.token,
          },
        };

        let response = await useAjaxEcom(
          "/customers/" + this.profile.id,
          options
        );

        if (response && response.data && response.data.id) {
          response.data = {
            ...response.data,
            vat_number: this.profile.vat_number,
          };

          let res = await useAjaxEcom(
            "/customers/" + this.profile.id,
            options
          );

          if (res && res.data && res.data.id) {
            res.data = { ...response.data, vat_number: this.profile.vat_number };
            this.profile = res.data;
            useAuthStore().user = useRootStore().clone(this.profile);
            toast("User Details Updated Successfully", {
              autoClose: true,
              type: "success",
            });
            this.is_profile_save_button_loading = false;
          }
        }
      } catch (err) {
        await useErrorHandler(err);
      }
    },

    //----------------------------------------------------------
    async verifyCurrentPassword(e) {
      this.errors = {
        password: "",
        new_password: "",
        new_password_confirmation: "",
      };

      e.preventDefault();


      if (!this.profile) {
        return;
      }

      try {

        this.is_update_password_button_loading = true;

        let body = {
          username: this.profile.username,
          password: this.change_password_inputs.current_password,
        };

        let options = {
          method: "post",
          params: body,
        };

        let response = await useAjaxEcom("/customers/auth/login", options);

        if (response) {
          if (!("success" in response)) {
            this.is_current_password_verified = true;
          }

          if (!response.success) {
            if (
              response.data &&
              response.data.error &&
              response.data.error.code
            ) {
              if (response.data.error.code === "401.99") {
                this.errors.password = "Current password is incorrect.";
              } else if (
                response.data.error.data &&
                response.data.error.data.errors
              ) {
                this.errors = response.data.error.data.errors;
              }
            }
          }
        }
        this.is_update_password_button_loading = false;
      } catch (err) {
        this.is_update_password_button_loading = false;
        await useErrorHandler(err);
      }
    },
    //----------------------------------------------------------
    async changePassword(e) {
      this.errors = {
        password: "",
        new_password: "",
        new_password_confirmation: "",
      };

      e.preventDefault();

      if (!this.profile) {
        return;
      }

      try {
        this.is_update_password_button_loading = true;
        let options = {
          method: "put",
          params: this.change_password_inputs,
          headers: {
            "X-Toolstation-Customer-Id": this.profile.token,
          },
        };

        let response = await useAjaxEcom(
          "/customers/" + this.profile.id + "/password",
          options
        );

        if (response) {
          if (!("success" in response)) {
            toast("Password Changed Successfully", {
              autoClose: true,
              type: "success",
            });

            this.change_password_inputs = {
              current_password: "",
              new_password: "",
              new_password_confirmation: "",
            };

            this.is_current_password_verified = false;
          }

          if (!response.success) {
            if (
              response.data &&
              response.data.error &&
              response.data.error.code
            ) {
              if (response.data.error.code === "401.99") {
                // this.errors.password = 'Current password is incorrect.';
              } else if (
                response.data.error.data &&
                response.data.error.data.errors
              ) {
                this.change_password_inputs.new_password_confirmation = "";

                this.errors = response.data.error.data.errors;
              }
            }
          }
        }
        this.is_update_password_button_loading = false;
      } catch (err) {
        this.is_update_password_button_loading = false;
        await useErrorHandler(err);
      }
    },
    async deleteCustomer() {
      try {
        let options = {
          method: "post",
          headers: {
            "X-Toolstation-Customer-Id": this.profile.token,
          },
        };

        let response = await useAjaxEcom(
          `/customers/${this.profile.id}/request-account-deletion`,
          options
        );
        if (response && !response.success) {
          // failed to delete here
          this.is_profile_delete_success = false;
          this.account_delete_modal = false;
          toast(`Coudln't request account deletion`, {
            autoClose: true,
            type: "error",
          });
        }
        toast(`Successfully requested account deletion`, {
          autoClose: true,
          type: "success",
        });
        this.is_profile_delete_success = true;
        this.account_delete_modal = false;
        // success
      } catch (error) { }
    },

    async handleTrackOrderHistory(orderId: string) {
      try {
        const response = await EcomCustomerService.redirectToTrackOrder(orderId);
        this.tracking_order_data = response.data;

      } catch (err) {
        await useErrorHandler(err);
      }
    }
  },
});

// Pinia hot reload
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot));
}
